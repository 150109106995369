<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <v-data-table
            :headers="headers"
            :items="dormantwodetaillist"
            item-key="id"
            sort-by="tl_date"
            sort-desc
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Dormant WO Detail</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-on="on"
                  @click="printDetail"
                  >Print</v-btn
                >
            
              </v-toolbar>

              
            </template>

            
          </v-data-table>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import genericService from "./../../../services/generic.service";

export default {
  data: () => ({
    dialog: false,
    search: "",
    expanded: [],
    dormantwodetaillist: [],
    singleExpand: false,
    headers: [
      { text: "WO", value: "id", sortable: false },
      { text: "Handler", value: "handler", sortable: false },
      { text: "Vessel", value: "vessel", sortable: false },
      { text: "WO Description", value: "description", sortable: false, width: '20%' },
      { text: "WO Claim Date", value: "claim_date", sortable: false },
      { text: "Last TimeLog Date", value: "tl_date", sortable: false },
      { text: "Last TimeLog Description", value: "tl_description", sortable: false, width: '20%' },
    ],
  }),

  computed: {
    ...mapGetters([ "currentUser" ]),

  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
        
      try {
        var _self = this;
        genericService.getDashboardDetailDormantWO(this.currentUser.id, this.currentUser.role, function(records){
           _self.dormantwodetaillist = records
        });
      }catch(exception){ 
        //console.log(exception);  
      }
    },

    printDetail() {
      window.print();
    },

  },
  components: {},
  mounted() {
    
  }
};
</script>
